import React, { useState, useEffect, useRef } from 'react';
import Table from './components/Table';
import { Button } from '@mui/material';
import { toPng } from 'html-to-image';
import {
  fetchResources,
  fetchProjects,
  fetchPlans,
  addPlan,
  updatePlan,
  removePlan,
  updateHours,
  updateDescription
} from './api';
import {parseISO} from "date-fns";

function App() {
  const [resources, setResources] = useState([]);
  const [projects, setProjects] = useState([]);
  const [plans, setPlans] = useState([]);
  const [openHolidayDialog, setOpenHolidayDialog] = useState(false);
  const appRef = useRef(null);
  useEffect(() => {
    const loadData = async () => {
      const [resourcesData, projectsData, plansData] = await Promise.all([
        fetchResources(),
        fetchProjects(),
        fetchPlans()
      ]);
      setResources(resourcesData);
      setProjects(projectsData);
      setPlans(plansData);
    };
    loadData();
  }, []);

  const handleAddHoliday = (holidayBlocks) => {
    // Add the new holiday blocks to the existing plans
    setPlans((prevPlans) => [...prevPlans, ...holidayBlocks]);
  };

  const handleAddPlan = async (plan) => {
    const newPlan = await addPlan(plan);
    setPlans([...plans, newPlan]);
  };
  const handleDuplicatePlan = async (plan) => {
    console.log('duplicate plan', plan);

    // Remove the `id` field to ensure it's treated as a new plan
    const { id, ...planWithoutId } = plan;

    try {
      // Add the new plan using the modified object
      const newPlan = await addPlan(planWithoutId);

      // Update the state with the new plan
      setPlans((prevPlans) => [...prevPlans, newPlan]);
    } catch (error) {
      console.error('Error duplicating plan:', error);
    }
  };

  const handleUpdatePlan = async (id, updatedPlan) => {
    const plan = await updatePlan(id, updatedPlan);
    setPlans(plans.map(p => (p.id === id ? plan : p)));
  };

  const handleRemoveProject = async (planId) => {
    await removePlan(planId);
    setPlans(plans.filter((itm) => itm.id != planId));
  };

  const handleHoursChange = async (planId, hours) => {
    await updateHours(planId, hours);

    const planIndex = plans.findIndex((itm) => itm.id === planId);

    // If the plan is not found, exit the function
    if (planIndex === -1) return;

    // Create a new copy of the plans array
    const updatedPlans = [...plans];

    // Update the specific project’s hours within the copied array
    updatedPlans[planIndex] = {
      ...updatedPlans[planIndex],
      hours: hours
    };

    // Set the updated array in state
    setPlans(updatedPlans);

    console.log(updatedPlans); // Check the updated plans array
  }
  const handleDescriptionChange = async (planId, description) => {
    await updateDescription(planId, description);

    const planIndex = plans.findIndex((itm) => itm.id === planId);

    // If the plan is not found, exit the function
    if (planIndex === -1) return;

    // Create a new copy of the plans array
    const updatedPlans = [...plans];

    // Update the specific project’s hours within the copied array
    updatedPlans[planIndex] = {
      ...updatedPlans[planIndex],
      description: description
    };

    // Set the updated array in state
    setPlans(updatedPlans);

    console.log(updatedPlans); // Check the updated plans array
  }

  const handleMoveProject = async (plan, newUserId, newDate) => {
    // Ensure newDate is in Date format
    const parsedDate = typeof newDate === 'string' ? parseISO(newDate) : newDate;

    // Destructure to remove `id` property from the plan
    const { id, ...planWithoutId } = plan;

    // Create the updated plan
    const updatedPlan = { ...planWithoutId, userId: newUserId, date: parsedDate };

    await removePlan(id);
    let plans2update = plans.filter((itm) => itm.id != id);

    const newPlan = await addPlan(updatedPlan);
    setPlans([...plans2update, newPlan]);

    // Update the plans state
    // setPlans((prevPlans) => {
    //   // Remove the old plan and add the updated one
    //   const filteredPlans = prevPlans.filter((p) => p.id !== plan.id);
    //   return [...filteredPlans, updatedPlan];
    // });

//    await handleRemoveProject(plan.id);
//     await handleAddPlan(updatedPlan);

  };

  const handleSaveAsImage = async () => {
    console.info('handleSaveAsImage');
    console.info(appRef.current);
    if (appRef) {
      console.info('appRef.current');
      try {
        const dataUrl = await toPng(appRef.current, { quality: 1.0 });
        // Format the current date and time
        const now = new Date();
        const formattedDate = now.toLocaleDateString('en-GB').replace(/\//g, '-'); // Format as DD-MM-YYYY
        const formattedTime = now.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '.'); // Format as HH.MM

        // Construct the file name
        const fileName = `Resource-management-${formattedDate}-${formattedTime}.png`;

        const link = document.createElement('a');
        link.download = fileName;
        link.href = dataUrl;
        link.click();
      } catch (error) {
        console.error('Failed to generate image:', error);
      }
    }
  };


  return (
      <div style={{userSelect: 'none'}}>
        <h1>Resource Planner</h1>
        <div style={{marginBottom: '10px'}}>
          <Button

              variant="contained"
              color="primary"
              onClick={handleSaveAsImage}
          >
            Save as Image
          </Button>
        </div>
        <div ref={appRef} style={{background:'#ffffff', paddingTop:'10px'}}>
          {/*<IconButton*/}
          {/*    onClick={() => setOpenHolidayDialog(true)}*/}
          {/*    aria-label="Add Holiday"*/}
          {/*    style={{marginBottom: '10px'}}*/}
          {/*>*/}
          {/*  <CalendarMonthIcon/>*/}
          {/*</IconButton>*/}

          {/*<HolidayDialog*/}
          {/*    open={openHolidayDialog}*/}
          {/*    onClose={() => setOpenHolidayDialog(false)}*/}
          {/*    onAddHoliday={handleAddHoliday}*/}
          {/*/>*/}

          {/* Render the table here */}
          <Table
              resources={resources}
              projects={projects}
              plans={plans}
              onAddPlan={handleAddPlan}
              onUpdatePlan={handleUpdatePlan}
              onDuplicatePlan={handleDuplicatePlan}
              onRemoveProject={handleRemoveProject}
              onHoursChange={handleHoursChange}
              onDescriptionChange={handleDescriptionChange}
              onHandleMoveProject={handleMoveProject}
          />
        </div>

      </div>
  );
}

export default App;