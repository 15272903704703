import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import ProjectCell from './ProjectCell';

function ResourceRow({ resource, days, plans, projects, onAddPlan, onRemoveProject, onHoursChange, onEditProject, onMoveProject }) {
    return (
        <TableRow>
            <TableCell style={{borderRight:'1px solid #ccc'}}>{resource.displayName}</TableCell>
            {days.map((day) => (
                <TableCell style={{ padding: '0' }} key={day}>
                <ProjectCell
                    key={`${resource.accountId}-${day}`}
                    day={day}
                    resource={resource}
                    plans={plans}
                    projects={projects}
                    onAddPlan={onAddPlan}
                    onRemoveProject={onRemoveProject}
                    onHoursChange={onHoursChange}
                    onEditProject={onEditProject}
                    onMoveProject={onMoveProject}
                />
                </TableCell>
            ))}
        </TableRow>
    );
}

export default ResourceRow;