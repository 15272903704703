import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, TableCell, Button } from '@mui/material';
import ResourceRow from './ResourceRow';
import AddProjectDialog from './AddProjectDialog';
import EditProjectDialog from './EditProjectDialog';
import { startOfWeek, addDays, addWeeks, format } from 'date-fns';
import { parseISO } from 'date-fns';

function ResourceTable({ resources, projects, plans, onAddPlan, onRemoveProject, onHoursChange, onDuplicatePlan, onDescriptionChange, onHandleMoveProject }) {
    const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 1 })); // Monday as start of week
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedResource, setSelectedResource] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    // Generate dates for the current week
    const daysOfWeek = Array.from({ length: 5 }, (_, i) => addDays(currentWeekStart, i));

    // Handle week navigation
    const handlePreviousWeek = () => {
        setCurrentWeekStart((prev) => addWeeks(prev, -1));
    };

    const handleNextWeek = () => {
        setCurrentWeekStart((prev) => addWeeks(prev, 1));
    };

    const handleOpenAddDialog = (resource, date) => {
        setOpenAddDialog(true);
        setSelectedResource(resource);
        setSelectedDate(date);
        console.log('Date:',date);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    // const handleRemoveProject = (projectId) => {
    //     setPlans((prevPlans) => prevPlans.filter((plan) => plan.id !== projectId));
    // };

    const handleOpenEditDialog = (project, hours) => {
        setSelectedProject(project);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedProject(null);
    };

    const handleAddProject = ({ project, hours, description, date }) => {
        onAddPlan({
            project_id: project.id,
            name:project.name,
            hours,
            color: project.color,
            description: description,
            date,
            userId:selectedResource
        });
    };

    const handleEditProject = (hours, description) => {
        if (selectedProject) {
            onHoursChange(selectedProject.id, hours);
            onDescriptionChange(selectedProject.id, description);
        }
    };




    return (
        <>
            {/* Week Navigation Controls */}
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                <Button onClick={handlePreviousWeek}>Previous Week</Button>
                <div>Week of {format(currentWeekStart, 'MMM dd, yyyy')}</div>
                <Button onClick={handleNextWeek}>Next Week</Button>
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            {daysOfWeek.map((date) => (
                                <TableCell key={date}>{format(date, 'EEE MMM dd')}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resources.map((resource) => (
                            <ResourceRow
                                key={resource.accountId}
                                resource={resource}
                                days={daysOfWeek}
                                plans={plans}
                                projects={projects}
                                onAddPlan={(resourceId, date) => {
                                    console.log('Date (RR):', date);
                                    handleOpenAddDialog(resource.accountId, date)}}
                                onRemoveProject={onRemoveProject}
                                onHoursChange={onHoursChange}
                                onEditProject={handleOpenEditDialog}
                                onMoveProject={onHandleMoveProject}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Add Project Dialog */}
            <AddProjectDialog
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                projects={projects}
                onAddProject={(projectData) => {handleAddProject({ ...projectData, date: selectedDate })}}
            />

            {/* Edit Project Dialog */}
            {selectedProject && (
                <EditProjectDialog
                    open={openEditDialog}
                    onClose={handleCloseEditDialog}
                    project={selectedProject}
                    onEditProject={handleEditProject}
                    onDuplicateProject={onDuplicatePlan}
                    onRemoveProject={onRemoveProject}
                />
            )}
        </>
    );
}

export default ResourceTable;