import axios from 'axios';

// const API_URL = 'http://localhost:5001/api';
const API_URL = 'https://rp.makinggiants.com/api';

export const fetchResources = async () => {
    const response = await axios.get(`${API_URL}/jira/users`);
    console.log(response.data.values);
    return response.data.values;
};

export const fetchProjects = async () => {
    const response = await axios.get(`${API_URL}/jira/projects`);
    return response.data;
};


export const fetchPlans = async () => {
    const response = await axios.get(`${API_URL}/plans`);
    return response.data;
};

export const addPlan = async (plan) => {
    const response = await axios.post(`${API_URL}/plans`, plan);
    return response.data;
};

export const updatePlan = async (id, plan) => {
    const response = await axios.put(`${API_URL}/plans/${id}`, plan);
    return response.data;
};
export const updateHours = async (id, hours) => {
    const response = await axios.put(`${API_URL}/plans/${id}/hours`, {hours});
    return response.data;
};
export const updateDescription = async (id, description) => {
    const response = await axios.put(`${API_URL}/plans/${id}/description`, {description});
    return response.data;
};
export const removePlan = async (id) => {
    const response = await axios.delete(`${API_URL}/plans/${id}`);
    return response.data;
};

