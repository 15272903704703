import React from 'react';
import ProjectBlock from './ProjectBlock';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { format } from 'date-fns';

function ProjectCell({ day, resource, plans, projects, onAddPlan, onRemoveProject, onHoursChange, onEditProject, onMoveProject }) {
    const formattedDay = format(day, 'yyyy-MM-dd');

    // Filter plans based on matching date and resource ID
    const filteredPlans = (Array.isArray(plans) ? plans : []).filter(
        (plan) => plan.userId === resource.accountId && format(new Date(plan.date), 'yyyy-MM-dd') === formattedDay
    );

    // Calculate total booked hours
    const totalBookedHours = filteredPlans.reduce((sum, plan) => sum + plan.hours, 0);

    // Determine progress bar color based on booked hours
    const progressBarColor =
        totalBookedHours < 7.5 ? '#ffc107' : totalBookedHours === 7.5 ? '#28a745' : '#dc3545'; // Yellow, Green, Red

    // Handle add project button click
    const handleAddProject = () => {
        onAddPlan(resource.accountId, day); // Pass `resourceId` as `userId`
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedPlan = JSON.parse(e.dataTransfer.getData('text/plain'));
        onMoveProject(droppedPlan, resource.accountId, formattedDay);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
                position: 'relative',
                minHeight: '50px',
                padding: '5px',
                borderRight: '1px solid #ccc',
                height: '120px',
                backgroundImage: `
                    repeating-linear-gradient(
                        to bottom,
                        transparent,
                        transparent 19px,
                        #efefef 19px,
                        #efefef 20px
                    )`,
                backgroundSize: '20px 100%',
            }}
        >
            {/* Render progress bar */}
            <div
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '2px',
                    width: `${Math.min((totalBookedHours / 7.5) * 100, 100)}%`, // Cap width at 100%
                    backgroundColor: progressBarColor,
                    transition: 'width 0.3s ease, background-color 0.3s ease',
                }}
            />

            {/* Render project blocks */}
            {filteredPlans.map((plan, index) => (
                <ProjectBlock
                    key={`${resource.accountId}-${day}-${index}-${plan.id}`}
                    plan={plan}
                    projects={projects}
                    onRemoveProject={onRemoveProject}
                    onHoursChange={onHoursChange}
                    onEditProject={onEditProject}
                    onDragStart={(e, plan) => {
                        e.dataTransfer.setData('text/plain', JSON.stringify(plan));
                    }}
                    onDragEnd={(e) => e.preventDefault()}
                />
            ))}

            {/* Add project button */}
            <IconButton
                onClick={handleAddProject}
                style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '5px',
                    backgroundColor: '#f0f0f0',
                    padding: '2px',
                }}
                size="small"
                aria-label="add project"
            >
                <AddIcon fontSize="small" />
            </IconButton>
        </div>
    );
}

export default ProjectCell;