import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl, IconButton
} from '@mui/material';
import AddIcon from "@mui/icons-material/Add";

function AddProjectDialog({ open, onClose, projects, onAddProject }) {
    const [selectedOption, setSelectedOption] = useState('project'); // 'project', 'holiday', 'custom', 'absence'
    const [selectedProject, setSelectedProject] = useState(null);
    const [customName, setCustomName] = useState('');
    const [description, setDescription] = useState('');
    const [hours, setHours] = useState(7.5);
    const [color, setColor] = useState('#ffffff'); // Default color (changeable for custom/absence)

    // Define color options
    const colorOptions = ['#a3d5a4', '#b3d7f4', '#daa6ae', '#FFC300', '#DAF7A6', '#C70039']; // 6 colors

    const handleAddProject = () => {
        let project;

        if (selectedOption === 'project') {
            if (!selectedProject) return; // Ensure a project is selected
            project = { ...selectedProject, color: '#a3d5a4' }; // Green for projects
        } else if (selectedOption === 'holiday') {
            project = { id: -1, name: 'Holiday', color: '#808080' }; // Gray for holiday
        } else if (selectedOption === 'custom' || selectedOption === 'absence') {
            if (!customName) return; // Ensure custom text is entered
            project = {
                id: selectedOption === 'custom' ? -2 : -3, // Unique IDs for custom and absence
                name: customName,
                color, // Selected color
            };
        }

        onAddProject({ project, hours, description });

        // reset
        setColor('');
        setDescription('');
        setHours(7.5);
        setSelectedProject(null);
        setSelectedOption('project');
        setCustomName('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{fontSize: '14px', borderBottom: '1px solid rgb(203,203,203)'}}>Add Project</DialogTitle>

            <DialogContent style={{padding: '20px 24px'}}>
                {/* Dropdown for selection */}
                <FormControl fullWidth margin="normal">
                    <InputLabel style={{fontSize:'12px'}}>Select Type</InputLabel>
                    <Select
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                        label="Select Type"
                    >
                        <MenuItem value="project"  style={{fontSize:'12px'}}>Project</MenuItem>
                        <MenuItem value="holiday" style={{fontSize:'12px'}}>Holiday Booking</MenuItem>
                        <MenuItem value="custom" style={{fontSize:'12px'}}>Custom</MenuItem>
                        <MenuItem value="absence" style={{fontSize:'12px'}}>Absence</MenuItem>
                    </Select>
                </FormControl>

                {/* Conditional rendering based on selected option */}
                {selectedOption === 'project' && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel style={{fontSize:'12px'}}>Select Project</InputLabel>
                        <Select
                            value={selectedProject ? selectedProject.id : ''}
                            onChange={(e) => {
                                const project = projects.find((p) => p.id === e.target.value);
                                setSelectedProject(project);
                            }}
                            style={{fontSize:'12px'}}
                            label="Select Project"
                        >
                            {projects.map((project) => (
                                <MenuItem key={project.id} value={project.id}  style={{fontSize:'12px'}}>
                                    {project.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                {(selectedOption === 'custom' || selectedOption === 'absence') && (
                    <TextField
                        label="Name"
                        fullWidth
                        InputProps={{
                            style: {
                                fontSize: '12px', // Adjust font size for input text
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '12px', // Adjust font size for the label
                            },
                        }}
                        margin="normal"
                        value={customName}
                        onChange={(e) => setCustomName(e.target.value)}
                    />
                )}

                <TextField
                    label="Description"
                    fullWidth
                    InputProps={{
                        style: {
                            fontSize: '12px', // Adjust font size for input text
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            fontSize: '12px', // Adjust font size for the label
                        },
                    }}
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <TextField
                    label="Hours"
                    type="number"
                    fullWidth
                    InputProps={{
                        style: {
                            fontSize: '12px', // Adjust font size for input text
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            fontSize: '12px', // Adjust font size for the label
                        },
                    }}
                    margin="normal"
                    value={hours}
                    onChange={(e) => setHours(Number(e.target.value))}
                    inputProps={{ min: 1 }}
                />

                {(selectedOption === 'custom' || selectedOption === 'absence') && (
                    <FormControl fullWidth margin="normal">
                        <InputLabel style={{fontSize:'12px', backgroundColor: 'white', padding: '3px 5px', marginLeft: '-5px'}}>Select Color</InputLabel>
                        <Select
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            style={{fontSize:'12px', display: 'flex', alignItems: 'center'}}
                        >
                            {colorOptions.map((colorOption) => (
                                <MenuItem key={colorOption} value={colorOption} style={{fontSize:'12px'}}>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            width: '20px',
                                            height: '20px',
                                            backgroundColor: colorOption,
                                            marginRight: '10px',
                                            borderRadius: '5px',
                                        }}
                                    />
                                    {colorOption}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions style={{padding: '0 24px 20px 24px'}}>
                <Button onClick={onClose} style={{fontSize: '12px'}}>Cancel</Button>
                <Button onClick={handleAddProject} color="primary" variant="contained"  style={{fontSize: '12px'}}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddProjectDialog;