import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { addDays, isWeekend, format } from 'date-fns';

function EditProjectDialog({ open, onClose, project, onEditProject, onDuplicateProject, onRemoveProject }) {
    const [hours, setHours] = useState(project ? project.hours : 8);
    const [description, setDescription] = useState(project ? project.description : '');
    const [menuAnchor, setMenuAnchor] = useState(null);

    useEffect(() => {
        if (project) {
            setHours(project.hours);
            setDescription(project.description);
        }
    }, [project]);

    // Handle menu opening and closing
    const handleMenuOpen = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleDuplicateToNextDate = () => {
        if (project) {
            let nextDate = addDays(new Date(project.date), 1);
            while (isWeekend(nextDate)) {
                nextDate = addDays(nextDate, 1);
            }
            const formattedNextDate = format(nextDate, 'yyyy-MM-dd');
            onDuplicateProject({ ...project, date: formattedNextDate });
        }
        handleMenuClose();
        onClose();
    };

    const handleRemoveProject = () => {
        onRemoveProject(project.id);
        onClose();
        handleMenuClose();
    };

    const handleSave = () => {
        onEditProject(hours, description);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{fontSize: '14px', borderBottom: '1px solid rgb(203,203,203)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Edit Project Hours
                <IconButton onClick={handleMenuOpen}>
                    <MoreVertIcon />
                </IconButton>
            </DialogTitle>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        width: '200px',
                    },
                }}
            >
                <MenuItem onClick={handleDuplicateToNextDate} style={{fontSize:'12px'}}>Duplicate to Next Date</MenuItem>
                <MenuItem onClick={handleRemoveProject} style={{fontSize:'12px'}}>Remove</MenuItem>
            </Menu>
            <DialogContent style={{padding: '20px 24px'}}>
                <TextField
                    label="Hours"
                    type="number"
                    fullWidth
                    margin="normal"
                    value={hours}
                    onChange={(e) => setHours(Number(e.target.value))}
                    inputProps={{ min: 0.5, step: 0.5, max: 10 }}
                    InputProps={{
                        style: {
                            fontSize: '12px', // Adjust font size for input text
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            fontSize: '12px', // Adjust font size for the label
                        },
                    }}
                />
                <TextField
                    label="Description"
                    fullWidth
                    InputProps={{
                        style: {
                            fontSize: '12px', // Adjust font size for input text
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            fontSize: '12px', // Adjust font size for the label
                        },
                    }}
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions style={{padding: '0 24px 20px 24px'}}>
                <Button onClick={onClose} style={{fontSize: '12px'}}>Cancel</Button>
                <Button onClick={handleSave} color="primary" variant="contained" style={{fontSize: '12px'}}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditProjectDialog;