import React, { useState, useRef } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DeleteIcon from '@mui/icons-material/Delete';

function ProjectBlock({ plan, onRemoveProject, onHoursChange, onEditProject, onDragStart, onDragEnd }) {
    const offset = 10;
    const draggingRef = useRef(false);
    const startYRef = useRef(0);
    const currentHeightRef = useRef(plan.hours * offset);
    const blockRef = useRef(null); // Reference to the block for dynamic dragging
    const [initialHeight, setInitialHeight] = useState(plan.hours * offset);
    const [currentHeight, setCurrentHeight] = useState(plan.hours * offset);

    const handleMouseDown = (e) => {
        e.stopPropagation();
        draggingRef.current = true;
        startYRef.current = e.clientY;
        setInitialHeight(plan.hours * offset);

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (!draggingRef.current) return;

        const deltaY = e.clientY - startYRef.current;
        const snappedHeight = Math.round((initialHeight + deltaY) / 10) * 10;
        const newHeight = Math.max(0, snappedHeight);
        currentHeightRef.current = newHeight;
        setCurrentHeight(newHeight);
    };

    const handleMouseUp = () => {
        draggingRef.current = false;
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);

        const newHours = Math.round((currentHeightRef.current / offset) * 2) / 2;
        onHoursChange(plan.id, newHours);
    };

    const enableDragging = (e) => {
        e.stopPropagation();
        if (blockRef.current) {
            blockRef.current.setAttribute('draggable', 'true'); // Enable dragging on the block
        }
    };

    const disableDragging = () => {
        if (blockRef.current) {
            blockRef.current.setAttribute('draggable', 'false'); // Disable dragging after drag ends
        }
    };

    return (
        <div
            ref={blockRef} // Reference to the block
            draggable="false" // Initially disable dragging
            onDragStart={(e) => {
                e.dataTransfer.setData('application/json', JSON.stringify(plan));
                onDragStart(e, plan);
            }}
            onDragEnd={(e) => {
                onDragEnd(e);
                disableDragging();
            }}
            style={{
                position: 'relative',
                backgroundColor: plan.color,
                marginBottom: '5px',
                padding: '5px',
                height: `${currentHeight}px`,
                border: '1px solid #ccc',
                borderLeft: `5px solid hsla(from ${plan.color} h s calc(l - 20))`,
                borderRadius: '6px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                fontSize: '11px',
            }}
        >
            <span>{plan.name} ({Math.round((currentHeight / offset) * 2) / 2}h)</span>
            <span style={{ fontSize: '10px', fontStyle: 'italic' }}>{plan.description}</span>

            <div style={{ position: 'absolute', top: '2px', right: '2px', display: 'flex', flexDirection:'row', gap: '5px' }}>
                {/* Move Icon serves as the handle */}

                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        onEditProject(plan);
                    }}
                    style={{ fontSize: '0.8rem' }}
                >
                    <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemoveProject(plan.id);
                    }}
                    style={{ fontSize: '0.8rem' }}
                >
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                    size="small"
                    onMouseDown={enableDragging} // Enable dragging only when the icon is pressed
                    style={{ fontSize: '0.8rem', cursor: 'grab' }}
                >
                    <OpenWithIcon fontSize="inherit" />
                </IconButton>

            </div>
            <div
                onMouseDown={handleMouseDown}
                style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    height: '5px',
                    cursor: 'ns-resize',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                }}
            />
        </div>
    );
}

export default ProjectBlock;